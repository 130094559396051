<template>
    <div>
        <div class="card-settings card-settings-summary">
            <div class="card-settings-summary-item">
                <h3>Empresa</h3>
                <span>Sari</span>
            </div>
            <div class="card-settings-summary-item">
                <h3>Identificacion</h3>
                <span>---</span>
            </div>
            <div class="card-settings-summary-item">
                <img class="logo-settings" src="https://seeklogo.com/images/G/Gallo_Cerveza-logo-DDCC925D1F-seeklogo.com.png" alt="">
            </div>
            <div class="card-settings-summary-item">
                <h3>Version de SmartRooster</h3>
                <span>Peru</span>
            </div>
            <div class="card-settings-summary-item">
                <h3>Plan Actual</h3>
                <span class="text-demo">DEMO</span>
                <div class="demo">
                    <p>Finaliza en <span>15 dias</span></p>
                </div>
            </div>
        </div>
        <div class="container-card-grid-settings">
            <div class="card-settings card-setting">
                <h3 class="title">Empresa</h3>
                <div class="container-body">
                    <span
                        >Configura la información de tu empresa y adapta Alegra
                        a tu negocio.</span
                    >
                </div>

                <div class="container-links">
                    <a href="#">Configure empresa</a>
                    <a href="#">Usuarios</a>
                    <a href="#">Mi perfil</a>
                </div>
            </div>
            <div class="card-settings card-setting">
                <h3 class="title">Empresa</h3>
                <div class="container-body">
                    <span
                        >Configura la información de tu empresa y adapta Alegra
                        a tu negocio.</span
                    >
                </div>

                <div class="container-links">
                    <a href="#">Configure empresa</a>
                    <a href="#">Usuarios</a>
                    <a href="#">Mi perfil</a>
                </div>
            </div>
            <div class="card-settings card-setting">
                <h3 class="title">Empresa</h3>
                <div class="container-body">
                    <span
                        >Configura la información de tu empresa y adapta Alegra
                        a tu negocio.</span
                    >
                </div>

                <div class="container-links">
                    <a href="#">Configure empresa</a>
                    <a href="#">Usuarios</a>
                    <a href="#">Mi perfil</a>
                </div>
            </div>
            <div class="card-settings card-setting">
                <h3 class="title">Empresa</h3>
                <div class="container-body">
                    <span
                        >Configura la información de tu empresa y adapta Alegra
                        a tu negocio.</span
                    >
                </div>

                <div class="container-links">
                    <a href="#">Configure empresa</a>
                    <a href="#">Usuarios</a>
                    <a href="#">Mi perfil</a>
                </div>
            </div>
            <div class="card-settings card-setting">
                <h3 class="title">Empresa</h3>
                <div class="container-body">
                    <span
                        >Configura la información de tu empresa y adapta Alegra
                        a tu negocio.</span
                    >
                </div>

                <div class="container-links">
                    <a href="#">Configure empresa</a>
                    <a href="#">Usuarios</a>
                    <a href="#">Mi perfil</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SettingsComponent"
};
</script>

<style lang="scss" scoped>
.card-settings {
    background: #fff;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 10%);
}
.card-settings-summary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 6px;
    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (min-width: 900px) {
        grid-template-columns: repeat(5, 1fr);
    }
}
.card-settings-summary-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ededed;
    position: relative;
    .text-demo{
        border: 2px solid #7367f0;
        border-radius: 5px;
        color: #7367f0;
        padding: .2rem .8rem;
    }
    .demo{
        position: absolute;
        bottom: -2rem;
        right: 50%;
        width: 170px;
        text-align: center;
        transform: translateX(50%);
        height: 26px;
        box-shadow: 1px 2px 5px rgb(0 0 0 / 10%);
        border-radius: 26px;
        padding: .2rem 1rem;
        background: #fff;
        p{
            margin: 0;
            span{
            color: #f99d36;
        }
        }
    }
    &:last-child{
        border-right: none;
    }
    h3{
        color: #707070;
        font-size: 13px;
    }
    span{
        color: #474747;
        font-size: 15px;
        font-weight: bolder;
    }
    .logo-settings{
        height: 80px;
        width: 80px;
        object-fit: contain;
        object-position: center;
    }
}
.container-card-grid-settings {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
    }
}
.card-setting {
    padding: 1.2rem;
    border-bottom: 4px solid #7367f0;
    border-radius: 6px;
    .title {
        font-size: 14px;
    }
    .container-body {
        margin: 1rem 0;
        span {
            font-size: 12px;
        }
    }
    .container-links {
        display: flex;
        flex-direction: column;
        a {
            font-size: 13px;
            margin: 0.2rem 0;
        }
    }
}
</style>
